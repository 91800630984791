export enum PROMO_CODE_GROUP {
    GIFT_CARD = 'gift_card',
    HDP = 'hdp',
    HERALD = 'herald',
    KICKBACK_ESCALATING = 'kickback_escalating',
    KICKBACK_ONE_YEAR = 'kickback_one_year',
    REFERRAL = 'referral',
    STUDENT = 'student'
}

export enum PROMO_INPUT_STATE {
    CLOSED = 'closed',
    OPEN = 'open',
    APPLIED = 'applied'
}